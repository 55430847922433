import { datadogRum } from '@datadog/browser-rum'

enum TDDRumError {
  notTracked = 'rum-not-tracked',
  replayNotTracked = 'incorrect-session-plan',
}

interface TDDRumInfo {
  replayUrl?: string
  isTracked: boolean
  isReplayTracked: boolean
}

export const getDDRumInfo = (): TDDRumInfo => {
  const replayUrl = datadogRum.getSessionReplayLink()
  if (!replayUrl) {
    return {
      isTracked: false,
      isReplayTracked: false,
    }
  }
  const parsedUrl = new URL(replayUrl)
  const errorType = parsedUrl ? parsedUrl.searchParams.get('error-type') : null
  const isTracked = !errorType || errorType !== TDDRumError.notTracked
  return {
    replayUrl,
    isTracked,
    isReplayTracked: isTracked && errorType !== TDDRumError.replayNotTracked,
  }
}
