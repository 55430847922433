import { clientRuntimeConfig } from '../../config'
import { getDDRumInfo } from '../getDDRumInfo'
import { getWindowDeviceInfo } from '../getWindowDeviceInfo'
import { StandardLog } from './types'

export const createLog = (codeLocation: string, message: string, data: any): StandardLog => {
  // Note: we might want to store the ruminfo object as a window object with a timeout
  // It can change, both by intentional interactions as well as automatically
  // One tricky aspect would be ensuring that it gets updated after DD Rum initialises
  const deviceInfo = getWindowDeviceInfo()
  const ddRumInfo = getDDRumInfo()
  const { rootVersion: webVersion, playerType } = clientRuntimeConfig
  return {
    message: `${codeLocation} > ${message}`,
    codeLocation,
    data: {
      ...(data || {}),
      webVersion,
      playerType,
      deviceInfo,
      ddRumInfo,
    },
  }
}
