import { DetailPanelContext } from '@/contexts/DetailPanelContext'
import { HeadingLabel, MetaDataLabel, SubheadingLabel } from '@/styles/fonts'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import LiveBanner from '../banners/LiveBanner'
import DetailPanelButton, { TDetailPanelButtonProps } from '../primitives/detail-panel-button'

interface TextLabelProps {
  fontSize: string
}

interface PanelProp {
  isDetailPanelShowing: boolean
}

type TModeKey = 'watch' | 'sgs'

type TModeContent = {
  title: string
  description?: string
  buttons: TDetailPanelButtonProps[]
}

type TModes = {
  [key in TModeKey]: TModeContent
}

const PanelWrapper = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
`

const DetailPanelCover = styled.div<PanelProp>`
  visibility: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? '0.5' : 0)};
  transition: opacity 0.5s, visibility 0.5s;
`

const DetailPanelWrapper = styled.div<PanelProp>`
  position: relative;
  z-index: 2;
  height: 100%;
  width: 691px;
  background-color: var(--surface-dark);
  opacity: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? '1' : 0)};
  visibility: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  padding: 64px 96px;
  transition: opacity 0.5s, visibility 0.5s;
`

const DetailPanelInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
`

const DetailPanelTitle = styled(HeadingLabel)<TextLabelProps>`
  color: var(--on-dark);
`

const DetailPanelDescription = styled(SubheadingLabel)`
  color: var(--on-dark);
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  width: 100%;
`

const Captions = styled.div`
  display: flex;
  & > * {
    padding-right: 24px;
  }
`

const Caption = styled(MetaDataLabel)`
  color: var(--on-dark);
`

export default function DetailPanel() {
  const {
    hideDetailPanel,
    contentUrl,
    videoObject,
    isDetailPanelShowing,
    videoId,
    requiresCouchRights,
    sgsPurchaseAvailable,
  } = useContext(DetailPanelContext)

  const { checkin, redeemCredit } = useContext(UserServiceContext)

  // if requiresCouchRights, then we should checkin
  useEffect(() => {
    if (requiresCouchRights) {
      checkin()
    }
  }, [requiresCouchRights, checkin])
  const router = useRouter()
  const { setFocus, focusKey, ref, getCurrentFocusKey } = useFocusable({
    focusKey: 'DETAIL_PANEL',
    isFocusBoundary: true,
  })

  useEffect(() => {
    const currentKey = getCurrentFocusKey()
    setFocus('TOP_BUTTON')
    return () => {
      setFocus(currentKey)
    }
  }, [setFocus, getCurrentFocusKey])

  useEffect(() => {
    const deviceInfo = getWindowDeviceInfo()

    if (isDetailPanelShowing && deviceInfo?.platform === 'hbbtv') {
      // scroll to top of page to make sure 'fixed' detail panel is visible inside ZoomWrap
      window.scrollTo(0, 0)
    }
  }, [isDetailPanelShowing])

  // create an onClick handler that will re-route to couchrights, video, OR video with fromBeginning=true

  if (!isDetailPanelShowing || !videoObject) {
    return null
  }

  const handleWatchPress = (next?: string) => {
    if (!next) {
      console.error('No contentUrl provided')
      return
    }
    router.push(next).then(() => {
      hideDetailPanel()
    })
  }

  const handleRedeemPress = (videoId: string, next?: string) => {
    redeemCredit(videoId).then(() => {
      handleWatchPress(next)
    })
  }
  const { captions, isLive, url } = videoObject
  const modes: TModes = {
    watch: {
      title: videoObject.title!,
      description: videoObject.description,
      buttons: [
        {
          focusKey: 'TOP_BUTTON',
          buttonTitle: 'WATCH',
          url: url!,
          videoId: videoId,
          onEnterPress: () => handleWatchPress(contentUrl),
          axeLabel: `Watch ${isLive ? 'live' : 'now'}: ${videoObject.title}`,
          theme: isLive ? 'live' : 'default',
        },
      ],
    },
    sgs: {
      title: 'Want to watch this game?',
      description: `Redeem your single game purchase to watch this live game immediately, or hold onto it to watch a different game at a later date or time.`,
      buttons: [
        {
          focusKey: 'TOP_BUTTON',
          buttonTitle: 'WATCH GAME NOW',
          url: url!,
          videoId: videoId,
          onEnterPress: () => {
            if (videoId) {
              handleRedeemPress(videoId, contentUrl)
            }
          },
          axeLabel: `Watch game now`,
        },
        {
          focusKey: 'BOTTOM_BUTTON',
          buttonTitle: 'SAVE FOR ANOTHER GAME',
          url: url!,
          videoId: videoId,
          onEnterPress: () => hideDetailPanel(),
          axeLabel: `Save for another game`,
          hideIcon: true,
        },
      ],
    },
  }

  const { title, description, buttons } = sgsPurchaseAvailable ? modes.sgs : modes.watch
  return (
    <PanelWrapper show={isDetailPanelShowing}>
      <DetailPanelCover isDetailPanelShowing={isDetailPanelShowing} />
      <DetailPanelWrapper isDetailPanelShowing={isDetailPanelShowing}>
        <DetailPanelInfo>
          {!isLive && captions?.length && (
            <Captions>
              {captions.map((caption, index) => (
                <Caption fontSize="xl" key={index}>
                  {caption}
                </Caption>
              ))}
            </Captions>
          )}
          {isLive ? <LiveBanner placement="detailPanel" /> : <></>}
          <DetailPanelTitle fontSize="6">{title}</DetailPanelTitle>
          {description ? <DetailPanelDescription fontSize="4">{description}</DetailPanelDescription> : <></>}
        </DetailPanelInfo>
        <FocusContext.Provider value={focusKey}>
          {isLive && (
            <ButtonsWrapper ref={ref}>
              {buttons.map((button, index) => (
                <DetailPanelButton key={index} {...button} />
              ))}
            </ButtonsWrapper>
          )}
        </FocusContext.Provider>
      </DetailPanelWrapper>
    </PanelWrapper>
  )
}
