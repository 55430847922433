import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

const ZoomWrapSyles = styled.div`
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: top left;
  margin-left: calc((1920px - 100vw) / 2);
  margin-top: calc((1080px - 100vh) / 2);

  @media (max-width: 1280px), (max-height: 720px) {
    & {
      transform: translate(-50%, -50%) scale(calc(min(1280 / 1920, 720 / 1080)));
    }
  }
`

export const ZoomWrap = ({ children }: PropsWithChildren) => {
  const deviceInfo = getWindowDeviceInfo()

  // only zoom wrap on comcast
  if (deviceInfo?.platform === 'hbbtv') {
    return <ZoomWrapSyles>{children}</ZoomWrapSyles>
  }

  return <>{children}</>
}
