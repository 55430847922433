export interface CrtTemplates {
  stage: string
  prod: string
}
export interface LocalXboxDrm {
  templates: CrtTemplates
  key: string
}
export function getLocalXboxDrmForConfig(): LocalXboxDrm | undefined {
  try {
    let json = process.env.NEXT_PUBLIC_LOCAL_XBOX_DRM
    if (json && json.length) {
      const parsed = JSON.parse(json)
      return parsed as LocalXboxDrm
    }
    return undefined
  } catch (_) {
    // ignore error: NEXT_PUBLIC_LOCAL_XBOX_DRM is not valid JSON on the client
    // this is expected in most non-dev-mode environments
    return undefined
  }
}
